@media screen and (max-width: 1024px){
  .homepage-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 50px 0;
  }
  .homepage-inner-section {
    width: 90%;
  }

  .createBtnMobile {
    width: 50%;
    height: 50px;
    border-radius: 25px;
    background: transparent;
    border: 2px solid white;
    font-size: 18px;
    color: white;
    margin: 50px auto;
    display: block;
  }
  .createBtnDesktop {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 3000px){
  .homepage-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 50px 0;
    margin: auto auto;
    width: 90%;
  }

  .homepage-inner-section {
    width: 50%;
    display: block;
    margin: auto auto;
  }

  .createBtnDesktop {
    width: 50%;
    height: 50px;
    border-radius: 25px;
    background: transparent;
    border: 2px solid white;
    font-size: 18px;
    color: white;
    margin: 50px auto;
    display: block;
  }
  .createBtnMobile {
    display: none;
  }
}

.homepage, .contact-page, .returnPolicy-page {
  height: auto;
  background-image: url("background.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
}