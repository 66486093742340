.blanket-gallery {
  display: flex;
  flex-direction: row;
}

.bgBtn {
  margin: auto auto;
  background-color: transparent;
  width: 10%;
  font-size: 100px;
  cursor: pointer;
}