.starmap-container {
  display: flex;
  flex-direction: row;
}

.processBtn-container {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

.processBtn {
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  width: 40%;
  border-radius: 10px;
  cursor: pointer;
}

.indexBtn-container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 15px;
}

.index-button {
  position: absolute;
  font-size: 100px;
  cursor: pointer;
  padding-bottom: 30px;
}