@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

*,
*:after,
*:before {
	box-sizing: border-box;
}

.checkbox-input {
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;

	&:checked + .checkbox-tile {
		border-color: #fff;
		box-shadow: 0 5px 10px rgba(#000, 0.1);
		color: #fff;
		&:before {
			transform: scale(1);
			opacity: 1;
			background-color: #1a1d28;
			border-color: #fff;
		}
		
		.checkbox-icon, .checkbox-label {
			color: #fff;
		}
	}
	
	&:focus + .checkbox-tile {
		border-color: #fff;
		box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #c874b2;
		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	width: 7rem;
	min-height: 7rem;
	border-radius: 0.5rem;
	border: 2px solid #fff;
	background-color: #1a1d28;
	box-shadow: 0 5px 10px rgba(#000, 0.1);
	transition: 0.15s ease;
	cursor: pointer;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 1.25rem;
		height: 1.25rem;
		border: 2px solid #1a1a1a;
		background-color: #1a1d28;
		border-radius: 50%;
		top: 0.25rem;
		left: 0.25rem;
		opacity: 0;
		transform: scale(0);
		transition: 0.25s ease;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
		background-size: 12px;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	&:hover {
		border-color: #fff;
		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-icon {
	transition: .375s ease;
	color: #bbb;
	svg {
		width: 3rem;
		height: 3rem;
	}
}

.checkbox-label {
	color: #bbb;
	transition: .375s ease;
	text-align: center;
}
