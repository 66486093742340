.cart {
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
}

@media screen and (max-width: 1024px)
{
  .desktopOnly { display: none }

  .cart {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1025px) and (max-width: 3000px)
{
  .mobileOnly { display: none }

  .cart-product-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
  }

  .cart-mockup-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .cartOptions {
    width: 50%;
    height: 100%;
    position: sticky;
    top: 90px;
  }

  .cart-image {
    display: block;
    margin: auto;
    width: 88%;
    border-radius: 10px;
  }
}

.cartOptions {
  display: flex;
  flex-direction: column;
}