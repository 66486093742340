.footer {
  padding-top: 5px;
}

@media screen and (max-width: 1024px){
  .footer-content {
    flex-direction: column;
  }

  .footer-links-content, .footer-socials { width: 90%; }
}

@media screen and (min-width: 1025px) and (max-width: 3000px){
  .footer-content {
    flex-direction: row;
  }

  .footer-links-content, .footer-socials { width: 45%; }
}

.footer-content {
  display: flex;
  width: 90%;
  margin: auto auto;
  margin-bottom: 30px;
}

.footer-links-content {
  display: flex;
  flex-direction: column;
  margin: auto auto;
}
.footer-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer-socials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto auto;
}