.radioGroup
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto 10px;
}

.radioButton {
  position: relative;
  width: 50px;
  height: 50px;
  appearance: none;
  border-radius: 50%;
  outline: none;
  transition: background-color 0.25s ease;
}

.radioButton:not(:checked):hover {
  background-color: #00ffff;
}