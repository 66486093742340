@media screen and (max-width: 1024px){
  .mobileBlanketSelection {
    margin: 10px 6%;
    width: 88%;
  }
  .sizeButtonContainer {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 3000px){

}

.product {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  flex-direction: column;
}

.mockupContainer {
  margin-top: 5%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.mockupImage {
  margin-right: 6%;
  position: relative;
  width: 70%;
  height: auto;
  border-radius: 10px;
}

.mockupMiniImageContainer {
  height: auto;
  margin-left: 6%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.mockupMiniImage {
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 10px;
}



.priceTag {
  position: relative;
}

.dollarNumbers {
  font-size: 50px;
  font-weight: bold;
}

.centNumbers {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 15;
}

.dollarSymbol {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 5;
}