.customize {
  display: flex;
  flex-direction: column;
}

.mobileHeaderButtons, .desktopHeaderButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: none;
  padding: 10px;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.mobileIndexButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin-top: 25px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1024px)
{
  .desktopHeader {
    display: none;
  }

  .customize {
    background: linear-gradient(#c874b2, #430d4b);
  }

  canvas {
    display: block;
    margin: auto auto;
    width: 90vw;
    height: 90vw;
  }

  .starmapContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  #celestial-map {
    width: 100%;
    text-align: center;
  }

  .left {
    height: 50%;
    margin-bottom: 25px;
  }
  .navigate {
    width: 100%;
  }
  .input {
    border-radius: 12px;
    border: 1px solid #fff;
    margin: auto auto;
    width: 95%;
    background: linear-gradient(#555, #111);/*#111;/*#f5d5e0;*/
    display: flex;
    justify-content: center;
  }

  .design {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    justify-content: space-around;
    margin-bottom: 10px;
  } 

  .background-container {
    background-color: #1a1d28;
    border: 2px solid #fff;
    border-radius: 10px;
    text-align: center;
    width: 80%;
  }

  .background-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
  }
  .indexButtons { display: none; }

}

@media screen and (min-width: 1025px) and (max-width: 3000px)
{
  .customize {
    display: grid;
    grid-template-columns: 25% 75%;
    height: 100vh;
    width: 100%;
  }
  
  .customize::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25%; /* Position the line at 25% of the container's width */
    width: 2px; /* Width of the line */
    height: 100%; /* Height of the line */
    background-color: white; /* Color of the line */
  }

  .mobileHeader {
    display: none;
  }

  .desktopHeader {
    height: 100px;
  }

  .left {
    background: linear-gradient(#555, #111);/*#111;/*#f5d5e0;*/
    width: 100%;
    height: 100%;
    order: 1;
  }

  .input {
    margin: auto auto;
    height: calc(100% - 100px); /* Adjust based on header height */
  }

  .indexButtons {
    display: flex;
    justify-content: flex-end;
  }

  .design {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .checkboxes {
    display: flex;
    flex-direction: row;
    padding: 15px;
  }

  .background-container {
    background-color: #1a1d28;
    border: 2px solid #fff;
    border-radius: 10px;
    text-align: center;
  }

  .background-buttons {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }

  .starmapContainer {
    order: 2;
    background: linear-gradient(#c874b2, #430d4b);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }

  #celestial-map {
    width: 60%;
    text-align: center;
  }

  .blanketImage {
    background: linear-gradient(#c874b2, #430d4b);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }

  .blanketImageContainer {
    background: #f5d5e0;
    border: 2px solid #c874b2;
    border-radius: 25px;
    width: 50%;
    height: auto;
  }

  .blanket {
    width: 100%;
    height: auto;
  }

  .accessorize {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .blanket-type-container, .blanket-size-container {
    background-color: #1a1d28;
    border: 2px solid #fff;
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
  }

  .blanket-type-buttons, .blanket-size-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .headerLogo {
    width: 90%;
  }
}

.navigate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.navigateLabel {
  margin-bottom: 3px;
  color: #fff
}

.locationTextbox {
  background-color: #1a1d28;
  padding: 15px;
  font-size: 18px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Changes the color of the calendar icon */
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Changes the color of the calendar icon */
}

input[type=date],
input[type=time] {
    width             : 100%;
    -webkit-appearance: none;
}




