.dropdown {
  width: 100%;
}

@media screen and (max-width: 1024px)
{
  .chooseBlanket {
    width: 88%;
    margin: 20px auto;
  }
  .blanket-select-text {
    display: none;
  }

  .productName{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  
  .productBlanketName{
    font-size: 5.5vw;
    width: 100%;
    text-align: start;
  }
  .productPriceForMockup {
    font-size: 5.5vw;
    margin: 0 0;
    word-wrap: break-word;
  }
  .special-moment-text {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 3000px){
  .productName { 
    order: 0;  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .productBlanketName{
    font-size: 30px;
  }
  .productPriceForMockup {
    font-size: 30px;
    margin: 0 0;
  }
  .special-moment-text {
    font-size: 30px;
  }
  
  .blanket-select-text {
    margin: 0 0;
  }
  .dropdown-choose-blanket { 
    order: 0;
    width: 100%;
  }
  .radioBtn-choose-size {
    order: 1
  }
  .blanketSelection {
    order: 1;
  }
  .nextButton {
    order: 2;
  }

  .chooseBlanket {
    margin-top: 5%;
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: column;
    position: sticky;
    left: 50%;
    top: 0%;
  }

}





.blanketLabel {
  width: 50%;
  text-align: start;
  margin-bottom: 5px;
}

.dropdown {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 12px;
  height: 50px;
  font-size: 24px;
  border-color: transparent;
  background-color: #fff;
  box-shadow: 0.15;
  border: 2px solid #444;
}



.sizeButtonContainer {
  display: flex;
  justify-content: space-between;
}

.sizeButtonVelveteen {
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  background-color: white;
  font-size: 20px;
  color: #444;
  width: 32%;
  border: 2px solid #444;
  appearance: none;
}

.sizeButtonSherpa {
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  background-color: white;
  font-size: 20px;
  color: #444;
  width: 49%;
  border: 2px solid #444;
  appearance: none;
}