.header {
  padding-bottom: 1px;
  z-index: 9999;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
}

.createBtn {
  cursor: pointer;
  background: transparent;
  border: 2px solid;
  border-radius: 15px;
  height: 30px;
  width: 80px;
  font-weight: bold;
}
.header-logo {
  padding: 3px;
  height: 60px;
  cursor: pointer;
  position: absolute;
  right: 5%;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.menuLink {
  cursor: pointer;
  font-weight: bold;
}

@media screen and (max-width: 1024px){
  .menuOpener {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    left: 5%;
    transform: translateX(-50%);
  }

  .desktop-menu {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 3000px){
  .menuOpener {
    display: none;
  }

  .desktop-menu {
    position: absolute;
    left: 5%;
  }
  .menuLink {
    margin: 2vw;
  }
}