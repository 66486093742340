.priceContainer{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.subtotal{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.priceLeft{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.priceRight{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.line{
  width: 100%;
  border: none;
  border-top: 2px solid #000; /* Adjust color and thickness as needed */
}

.priceLabelText{
  margin: 5px;
  color: #30313d;
  font-size: 16px;
}