.accordion {
  width: 100%;
  background: #f1f1f1;
  width: 90%;
  margin: 0px auto;
}

.section, .FAQ-section {
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.section-header, .FAQ-section-header {
  padding: 10px;
  border-radius: 0px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.section-header::after {
  content: '\25BC';
  color: #999;
  position: absolute;
  right: 10px;
}
.section.active .section-header::after {
  content: '\25B2';
  color: #999;
  border-bottom: 0px solid transparent;
}

.FAQ-section-header::after {
  content: '+';
  color: #999;
  position: absolute;
  right: 10px;
}
.FAQ-section.active .FAQ-section-header::after {
  content: '-';
  color: #999;
  border-bottom: 0px solid transparent;
}

.section-content {
  padding: 10px;
  display: none;
  color: #444;
  font-weight: bold;
}

.section.active .section-content {
  display: block;
}

.FAQ-section.active .section-content {
  display: block;
}