.next {
  background: #1a1d28;
  border: 2px solid #fff;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  padding: 15px 20px;
  width: 40%;
  cursor: pointer;
}

.next:hover {
  font-weight: bold;
}